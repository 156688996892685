import { useEffect, useState } from "react";
import { TextField } from "@mui/material";
import "./setup_page.css";
import { MuiTelInput } from "mui-tel-input";
import { login, setupAccount, setupValidate } from "../../api";
import Copyright from "../copyright/copyright";
import Headbar from "../headbar/headbar";
import OTPSender from "./otp_sender";

const SESSION_VALIDTY = {
  LOADING: "LOADING",
  INVALID: "INVALID",
  VALID: "VALID",
};

export default function SetupPage() {
  document.title = "BinLens - Setup Account";
  const urlParams = new URLSearchParams(window.location.href.split("?")[1]);
  const TOKEN = urlParams.get("token");

  let [email, setEmail] = useState("");
  let [accountType, setAccountType] = useState(null);
  let [validity, setValidity] = useState(SESSION_VALIDTY.LOADING);
  let [username, setUsername] = useState("");
  let [phone, setPhone] = useState("");
  let [pass1, setPass1] = useState("");
  let [pass2, setPass2] = useState("");
  let [otp, setOTP] = useState("");
  let [confirmText, setConfirmText] = useState("");

  const validateToken = async () => {
    try {
      let account = await setupValidate(TOKEN);
      setEmail(account.email);
      setAccountType(account.__t);
      setValidity(SESSION_VALIDTY.VALID);
    } catch {
      setValidity(SESSION_VALIDTY.INVALID);
    }
  };
  useEffect(() => {
    validateToken();
  }, []);

  const submitSetup = async () => {
    setConfirmText("");
    if (
      username === "" ||
      phone === "" ||
      pass1 === "" ||
      pass2 === "" ||
      otp === ""
    ) {
      alert("Please fill out every form field.");
      return;
    }
    if (!validUsername(username)) {
      alert("Please fill out a valid username.");
      return;
    }
    if (!validPhone(phone)) {
      alert("Please fill out a valid phone number.");
      return;
    }
    if (strongPasswordScore(pass1) < 4) {
      alert("Ensure the Strong Password policy is met.");
      return;
    }
    if (pass1 !== pass2) {
      alert("Please ensure both passwords you entered are the same.");
      return;
    }
    let confirmation = await setupAccount(TOKEN, username, phone, pass1, otp);
    setConfirmText(confirmation);
    if (confirmation === "User account setup.") {
      let res = await login(username, pass1);
      if (accountType === "DownloadUser") {
        window.location.assign(`#/download?token=${res.token}`);
        return;
      }
      window.location.assign(`#/upload?token=${res.token}`);
    }
  };

  const enterClick = (e) => {
    if (e.key === "Enter") {
      submitSetup();
    }
  };

  /**
   * Determine if an username is valid.
   */
  const validUsername = (username) => {
    return username.match(/^[a-zA-Z0-9]+$/);
  };

  /**
   * Determine if a phone number is valid.
   */
  const validPhone = (phone) => {
    return phone.match(
      /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im
    );
  };

  /**
   * Determine the strong password score for a given
   * password.
   * Valid passwords must score 4 or more.
   */
  const strongPasswordScore = (password) => {
    let score = 0;

    if (password.length >= 7) score++;
    if (password.search(/\d/) !== -1) score++;
    if (password.search(/[a-z]/) !== -1) score++;
    if (password.search(/[A-Z]/) !== -1) score++;

    return score;
  };

  let pass_score = strongPasswordScore(pass1);

  if (validity === SESSION_VALIDTY.LOADING) return null;
  if (validity === SESSION_VALIDTY.INVALID)
    return (
      <div className="setup-page">
        <Headbar title="ObjectSecurity Secure FTP Portal" />
        <h1 className="invite-expiration">
          Your invite link is no longer valid.
        </h1>
      </div>
    );
  return (
    <div className="setup-page">
      <Headbar title="ObjectSecurity Secure FTP Portal" />
      <h1>Hello {email},</h1>
      <p>Let's setup your account.</p>
      <div className="setup-center">
        <h2>Personal Info</h2>
        <p style={{ marginBottom: 20 }}>
          Your username must contain only numbers and/or digits.
        </p>
        {username !== "" && (
          <>
            {!validUsername(username) && (
              <p className="invalid-username">Invalid Username</p>
            )}
            {validUsername(username) && (
              <p className="valid-username">Valid Username</p>
            )}
          </>
        )}

        <TextField
          onKeyDown={enterClick}
          className={
            username === ""
              ? "enter-username"
              : validUsername(username)
              ? "enter-username enter-username-valid"
              : "enter-username enter-username-invalid"
          }
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          label="Username"
          fullWidth
        />

        {phone !== "" && (
          <>
            {!validPhone(phone) && (
              <p className="invalid-username">Invalid Phone Number</p>
            )}
            {validPhone(phone) && (
              <p className="valid-username">Valid Phone Number</p>
            )}
          </>
        )}
        <MuiTelInput
          onKeyDown={enterClick}
          className={
            phone === ""
              ? "enter-phone"
              : validPhone(phone)
              ? "enter-phone enter-phone-valid"
              : "enter-phone enter-phone-invalid"
          }
          label="Phone Number"
          fullWidth
          value={phone}
          preferredCountries={["US"]}
          onChange={(e) => setPhone(e)}
          splitCallingCode={true}
        />

        <h2>Account Security</h2>
        <p style={{ marginBottom: 20 }}>
          To conform with our Strong Password policy, you are required to use a
          sufficiently strong password.
          <br />
          <br /> The password must be longer than 7 characters. It must include
          atleast 1 digit, 1 uppercase letter, and 1 lowercase letter.
        </p>

        {pass1 !== "" && (
          <>
            {pass_score < 4 && (
              <p className="invalid-username">Password Not Strong Enough</p>
            )}
            {pass_score >= 4 && (
              <p className="valid-username">Valid Password</p>
            )}
          </>
        )}
        {pass1 !== "" && (
          <div className="pass-strength">
            {pass_score > 0 && (
              <div
                className="pass-strength-on"
                style={{
                  background: pass_score >= 4 ? "var(--green-color)" : "",
                }}
              ></div>
            )}
            {pass_score <= 0 && <div className="pass-strength-off"></div>}

            {pass_score > 1 && (
              <div
                style={{
                  background: pass_score >= 4 ? "var(--green-color)" : "",
                }}
                className="pass-strength-on"
              ></div>
            )}
            {pass_score <= 1 && <div className="pass-strength-off"></div>}

            {pass_score > 2 && (
              <div
                style={{
                  background: pass_score >= 4 ? "var(--green-color)" : "",
                }}
                className="pass-strength-on"
              ></div>
            )}
            {pass_score <= 2 && <div className="pass-strength-off"></div>}

            {pass_score > 3 && (
              <div
                style={{
                  background: pass_score >= 4 ? "var(--green-color)" : "",
                }}
                className="pass-strength-on"
              ></div>
            )}
            {pass_score <= 3 && <div className="pass-strength-off"></div>}
          </div>
        )}

        <TextField
          onKeyDown={enterClick}
          className={
            pass1 === ""
              ? "enter-pass1"
              : pass_score >= 4
              ? "enter-pass1 enter-pass1-valid"
              : "enter-pass1 enter-pass1-invalid"
          }
          value={pass1}
          onChange={(e) => setPass1(e.target.value)}
          label="New Password"
          fullWidth
          type={"password"}
        />
        {pass2 !== "" && (
          <>
            {pass1 !== pass2 && (
              <p className="invalid-username">Passwords Must Match</p>
            )}
            {pass1 === pass2 && (
              <p className="valid-username">Passwords Match</p>
            )}
          </>
        )}
        <TextField
          onKeyDown={enterClick}
          className={
            pass2 === ""
              ? "enter-pass2"
              : pass1 === pass2
              ? "enter-pass2 enter-pass2-valid"
              : "enter-pass2 enter-pass2-invalid"
          }
          value={pass2}
          onChange={(e) => setPass2(e.target.value)}
          label="Re-Enter New Password"
          fullWidth
          type={"password"}
        />
        <p style={{ marginBottom: 20 }}>
          Please store your username & password somewhere safe. If you lose your
          password, contact ObjectSecurity.
        </p>
        <p>
          Click the blue mail button above to recieved a 6-digit
          one-time-passcode (OTP). Enter this OTP in the field below to verify
          your account.
        </p>
        <OTPSender
          otp={otp}
          setOTP={setOTP}
          token={TOKEN}
          onKeyDown={enterClick}
        />
        <button onClick={submitSetup} className="create-account-button">
          Setup Account
        </button>
      </div>
      <p className="confirm-text">{confirmText}</p>

      <Copyright />
    </div>
  );
}
