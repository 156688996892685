import { useEffect, useState, useRef } from "react";
import "./admin_page.css";
import {
  deleteUser,
  genUserInvite,
  getAllAssets,
  getAllUsers,
  getAsset,
  makeAssetAvailableForDownload,
} from "../../api";
import { Trash, Box, Link } from "react-feather";
import Headbar from "../headbar/headbar";
import Copyright from "../copyright/copyright";
import AdminAsset from "./admin_asset";
import NewUserRegister from "./new_user_register";
import AssetAttacher from "./asset_attacher";

export default function AdminPage() {
  document.title = "BinLens - Admin";
  const hiddenFileInput = useRef();

  // get token
  const urlParams = new URLSearchParams(window.location.href.split("?")[1]);
  const token = urlParams.get("token");

  // manageable items
  let [users, setUsers] = useState([]);
  let [assets, setAssets] = useState([]);
  let [registeringNewUser, setRegisteringNewUser] = useState(false);
  let [uploadingFile, setUploadingFile] = useState(null);

  const refreshUsers = async () => {
    let users = await getAllUsers(token);
    setUsers(users);
  };

  /**
   * Get downloadable assets store on the backend.
   */
  const refreshAssets = async () => {
    let assets = await getAllAssets(token);
    setAssets(assets.reverse());
  };

  // grab all assets
  useEffect(() => {
    refreshUsers();
    refreshAssets();
  }, []);

  return (
    <div className="admin-page">
      <Headbar title="ObjectSecurity SFTP Admin Portal" logout />

      <div className="admin-center">
        <h2>Manage Users</h2>
        <div className="admin-assets">
          {users.map((user) => (
            <User token={token} user={user} refresh={refreshUsers} />
          ))}
        </div>
        <button
          className="upload-assets-button"
          onClick={() => setRegisteringNewUser(true)}
        >
          Register New User
        </button>

        <NewUserRegister
          registeringNewUser={registeringNewUser}
          token={token}
          done={() => {
            setRegisteringNewUser(false);
            refreshUsers();
          }}
        />

        <h2>Manage File Downloads</h2>
        <div className="admin-assets">
          {assets.map((asset) => (
            <AdminAsset token={token} asset={asset} refresh={refreshAssets} />
          ))}
        </div>
        <button
          className="upload-assets-button"
          onClick={() => hiddenFileInput.current.click()}
        >
          Upload File
        </button>

        <FileUploadLoadDisplayer uploadingFile={uploadingFile} />
      </div>

      <input
        type="file"
        multiple
        onChange={async (e) => {
          let file = e.target.files[0];
          await makeAssetAvailableForDownload(
            token,
            file,
            (e) => {
              // progressFunc
              setUploadingFile({
                name: file.name,
                loaded: e.loaded,
                total: e.total,
              });
            },
            () => {
              // completionFunc
              setUploadingFile(null);
              refreshAssets();
            }
          );
        }}
        ref={hiddenFileInput}
        style={{ display: "none" }}
      />

      <Copyright />
    </div>
  );
}

function User({ token, user, refresh }) {
  let [attachingAssets, setAttachingAssets] = useState(false);

  return (
    <div className="managed-user">
      <div>
        <h3>Email: {user.email}</h3>
        <h3>
          Username: {user.username ? user.username : "Not yet configured."}
        </h3>
        <h3>Phone: {user.phone ? user.phone : "Not yet configured."}</h3>
        <h3>Account Type: {user.__t}</h3>
        {user.__t === "DownloadUser" && (
          <>
            <h3>Assets:</h3>
            {user.assets.length === 0 && <h3>&emsp; None.</h3>}
            {user.assets.map((asset_id) => (
              <InlineAsset asset_id={asset_id} token={token} />
            ))}
            <AssetAttacher
              attachingAssets={attachingAssets}
              user={user}
              token={token}
              done={() => {
                setAttachingAssets(false);
                refresh();
              }}
            />
          </>
        )}
      </div>

      <div className="row">
        {user.__t === "DownloadUser" && (
          <Box className="icon" onClick={() => setAttachingAssets(true)} />
        )}
        <Link
          className="icon"
          onClick={async () => {
            let invite_link = await genUserInvite(token, user);
            await navigator.clipboard.writeText(invite_link);
            alert(`Copied ${invite_link} to clipboard.`);
          }}
        />
        <Trash
          className="icon"
          onClick={async () => {
            if (
              window.confirm(
                `Are you sure you want to delete user ${user.email}?`
              )
            ) {
              await deleteUser(token, user._id);
              refresh();
            }
          }}
        />
      </div>
    </div>
  );
}

function FileUploadLoadDisplayer({ uploadingFile }) {
  if (!uploadingFile) return null;
  let max = "100";
  let value = (uploadingFile.loaded / uploadingFile.total) * 100;
  value += "";
  return (
    <div className="file-upload-load-displayer">
      <label for="file">Uploading {uploadingFile.name}:</label>
      <progress id="file" max={max} value={value}>
        {value}%
      </progress>
    </div>
  );
}

function InlineAsset({ asset_id, token }) {
  let [asset, setAsset] = useState(null);
  const init = async () => {
    let asset = await getAsset(token, asset_id);
    setAsset(asset);
  };
  useEffect(() => {
    init();
  }, []);
  if (!asset) return null;
  return <div className="inline-asset">&emsp; {asset.key}</div>;
}
