import { useState } from "react";
import { sendVerificationEmail } from "../../api";
import { TextField } from "@mui/material";
import { Tooltip } from "react-tippy";
import { Check, Mail } from "react-feather";

const OTP_LENGTH = 6;

export default function OTPSender({ token, otp, setOTP, onKeyDown }) {
  let [sent, setSent] = useState(false);

  const sendEmail = async () => {
    if (sent) return;
    setSent(true);
    sendVerificationEmail(token);
  };

  return (
    <div className="otp-sender">
      <TextField
        onKeyDown={onKeyDown}
        className={
          otp === ""
            ? "enter-username"
            : otp.length === OTP_LENGTH
            ? "enter-username enter-username-valid"
            : "enter-username enter-username-invalid"
        }
        value={otp}
        onChange={(e) => setOTP(e.target.value)}
        label="OTP"
        fullWidth
      />
      <Tooltip
        className="otp-tooltip"
        title={sent ? "Email sent." : "Send Email with OTP."}
        position="top"
        trigger="mouseenter"
      >
        <button
          onClick={sendEmail}
          style={{
            backgroundColor: sent ? "var(--green-color)" : "var(--sec-color)",
          }}
        >
          {sent ? <Check className="icon" /> : <Mail className="icon" />}
        </button>
      </Tooltip>
    </div>
  );
}
