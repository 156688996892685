import { useState } from "react";
import { Checkbox, TextField } from "@mui/material";
import { registerDownloadAccount, registerUploadAccount } from "../../api";

export default function NewUserRegister({ registeringNewUser, token, done }) {
  let [email, setEmail] = useState("");
  let [isDownload, setIsDownload] = useState(true);

  if (!registeringNewUser) {
    return null;
  }

  const complete = async () => {
    if (email === "") return;
    if (isDownload) {
      await registerDownloadAccount(token, email);
    } else {
      await registerUploadAccount(token, email);
    }
    done();
  };

  return (
    <div className="new-user-register">
      <TextField
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        label="Email"
      />
      <div className="row">
        Is Download Account{" "}
        <Checkbox
          onChange={(e) => setIsDownload(!isDownload)}
          checked={isDownload}
        />
      </div>

      <div className="row">
        <button className="upload-assets-button" onClick={done}>
          Cancel
        </button>
        <button className="upload-assets-button" onClick={complete}>
          Submit
        </button>
      </div>
    </div>
  );
}
