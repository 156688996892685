import { useState, useEffect } from "react";
import { updateUser, getAllAssets } from "../../api";
import { Checkbox } from "@mui/material";

export default function AssetAttacher({ attachingAssets, user, token, done }) {
  let [assets, setAssets] = useState([]);
  let [selectedAssets, setSelectedAssets] = useState(user.assets);

  const refresh = async () => {
    setSelectedAssets(user.assets);
    let assets = await getAllAssets(token);
    setAssets(assets);
  };
  useEffect(() => {
    if (!attachingAssets) return;
    refresh();
  }, [attachingAssets]);

  const complete = async () => {
    await updateUser(token, user._id, selectedAssets);
    done();
  };

  if (!attachingAssets) return null;
  return (
    <div className="asset-attacher">
      <div className="asset-attacher-assets">
        {assets.map((asset) => (
          <AssetToggle
            asset={asset}
            selectedAssets={selectedAssets}
            setSelectedAssets={setSelectedAssets}
          />
        ))}
        <div className="row">
          <button onClick={done}>Cancel</button>
          <button onClick={complete}>Confirm</button>
        </div>
      </div>
    </div>
  );
}

function AssetToggle({ asset, selectedAssets, setSelectedAssets }) {
  return (
    <div className="asset-toggle">
      <h3>{asset.key}</h3>
      <Checkbox
        onChange={(e) => {
          setSelectedAssets((selectedAssets) => {
            if (selectedAssets.includes(asset._id)) {
              return selectedAssets.filter((a) => a !== asset._id);
            }
            return [...selectedAssets, asset._id];
          });
        }}
        checked={selectedAssets.includes(asset._id)}
      />
    </div>
  );
}
