function getBasePath() {
  return window.location.protocol + "//" + window.location.host;
}

// ---

/**
 * Login.
 */
export async function login(username, password) {
  let res = await fetch(getBasePath() + `/login`, {
    method: "POST",
    headers: new Headers({
      "Content-Type": "application/json",
    }),
    body: JSON.stringify({
      username,
      password,
    }),
  });
  let parsed = await res.json();
  return parsed;
}

/**
 * Get asset information.
 */
export async function getAssetInfo(token) {
  let res = await fetch(getBasePath() + `/download/assets?token=${token}`);
  let parsed = await res.json();
  return parsed;
}

/**
 * Download the asset.
 */
export async function downloadAsset(token, id) {
  const url = getBasePath() + `/download/download/${id}?token=${token}`;
  window.open(url, "_blank");
}

/**
 * Upload an asset.
 */
export async function uploadAsset(
  token,
  file,
  description,
  progressFunc,
  completeFunc
) {
  let body = new FormData();
  body.append(file.name, file);
  body.append("description", description);

  let request = new XMLHttpRequest();
  request.upload.addEventListener("progress", progressFunc);
  request.onreadystatechange = function () {
    if (request.readyState === 4) {
      completeFunc();
    }
  };
  request.open("post", getBasePath() + `/upload/upload`);
  request.setRequestHeader("Authorization", `Bearer ${token}`);
  request.send(body);
  return request;
}

/**
 * Setup new upload account.
 */
export async function setupAccount(token, username, phone, password, otp) {
  let res = await fetch(getBasePath() + `/setup/setup`, {
    method: "PUT",
    body: JSON.stringify({
      username,
      phone,
      password,
      otp,
    }),
    headers: new Headers({
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    }),
  });
  let parsed = await res.json();
  return parsed.message;
}

export async function sendVerificationEmail(token) {
  let res = await fetch(getBasePath() + `/setup/email`, {
    method: "POST",
    headers: new Headers({
      Authorization: `Bearer ${token}`,
    }),
  });
  let parsed = await res.text();
  return parsed;
}

export async function setupValidate(token) {
  let res = await fetch(getBasePath() + `/setup/validate`, {
    method: "GET",
    headers: new Headers({
      Authorization: `Bearer ${token}`,
    }),
  });
  let user = await res.json();
  return user;
}

/**
 * Get the most recent upload description.
 */
export async function getUploadDescription(token) {
  let res = await fetch(getBasePath() + `/upload/description`, {
    method: "GET",
    headers: new Headers({
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    }),
  });
  let parsed = await res.text();
  return parsed;
}

/**
 * Check that a session token is valid (hasn't expired).
 * This function is called on page load.
 * It redirects the user to the login page, should their
 * session be expired.
 */
export async function isValidSession(token) {
  let res = await fetch(getBasePath() + `/check_session/${token}`, {
    method: "GET",
  });
  if (!res.ok) return false;
  let parsed = await res.text();
  if (parsed === "Valid token.") return true;
  return false;
}

// --- ADMIN ASSET MANAGEMENT ---

/**
 * Get all assets that are currently available for download on
 * the SFTP portal.
 */
export async function getAllAssets(token) {
  let res = await fetch(getBasePath() + `/admin/asset`, {
    method: "GET",
    headers: new Headers({
      Authorization: `Bearer ${token}`,
    }),
  });
  if (!res.ok) return [];
  let parsed = await res.json();
  return parsed;
}

export async function getAsset(token, id) {
  let res = await fetch(getBasePath() + `/admin/asset/${id}`, {
    method: "GET",
    headers: new Headers({
      Authorization: `Bearer ${token}`,
    }),
  });
  if (!res.ok) return null;
  let parsed = await res.json();
  return parsed;
}

/**
 * Delete an asset, making it unavailable for download
 * on the SFTP portal.
 */
export async function deleteAsset(token, _id) {
  let res = await fetch(getBasePath() + `/admin/asset/${_id}`, {
    method: "DELETE",
    headers: new Headers({
      Authorization: `Bearer ${token}`,
    }),
  });
  return res.ok;
}

/**
 * Upload an asset.
 */
export async function makeAssetAvailableForDownload(
  token,
  file,
  progressFunc,
  completeFunc
) {
  let body = new FormData();
  body.append(file.name, file);
  let request = new XMLHttpRequest();
  request.upload.addEventListener("progress", progressFunc);
  request.onreadystatechange = function () {
    if (request.readyState === 4) {
      completeFunc();
    }
  };
  request.open("post", getBasePath() + `/admin/asset`);
  request.setRequestHeader("Authorization", `Bearer ${token}`);
  request.send(body);
  return request;
}

// --- ADMIN USER MANAGEMENT ---

export async function registerDownloadAccount(token, email) {
  let res = await fetch(getBasePath() + `/admin/user/download`, {
    method: "POST",
    headers: new Headers({
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    }),
    body: JSON.stringify({
      email,
    }),
  });
  return res.ok;
}

export async function registerUploadAccount(token, email) {
  let res = await fetch(getBasePath() + `/admin/user/upload`, {
    method: "POST",
    headers: new Headers({
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    }),
    body: JSON.stringify({
      email,
    }),
  });
  return res.ok;
}

export async function updateUser(token, user_id, assets) {
  let res = await fetch(getBasePath() + `/admin/user/${user_id}`, {
    method: "PUT",
    headers: new Headers({
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    }),
    body: JSON.stringify({
      assets,
    }),
  });
  return res.ok;
}

export async function deleteUser(token, user_id) {
  let res = await fetch(getBasePath() + `/admin/user/${user_id}`, {
    method: "DELETE",
    headers: new Headers({
      Authorization: `Bearer ${token}`,
    }),
  });
  return res.ok;
}

export async function getAllUsers(token) {
  let res = await fetch(getBasePath() + `/admin/user`, {
    method: "GET",
    headers: new Headers({
      Authorization: `Bearer ${token}`,
    }),
  });
  if (!res.ok) return [];
  let parsed = await res.json();
  return parsed;
}

export async function genUserInvite(token, user) {
  let res = await fetch(getBasePath() + `/admin/user/${user._id}/invite`, {
    method: "GET",
    headers: new Headers({
      Authorization: `Bearer ${token}`,
    }),
  });
  let parsed = await res.json();
  if (user.__t === "UploadUser")
    return `${getBasePath()}/#/dsa?token=${parsed.token}`;
  return `${getBasePath()}/#/setup?token=${parsed.token}`;
}
