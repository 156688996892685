import "./headbar.css";
import "react-tippy/dist/tippy.css";
import { Tooltip } from "react-tippy";

// icons
import { LogOut } from "react-feather";

export default function Headbar({ title, logout }) {
  return (
    <>
      <header className="headbar">
        <img
          src="/binlens_logo.png"
          alt="BinLens Logo"
          className="project-logo"
        />

        {title && <h1>{title}</h1>}

        {logout && (
          <Tooltip
            // options
            title="Log Out"
            position="left"
            trigger="mouseenter"
          >
            <div
              className="icon-wrap"
              onClick={() => {
                window.location.assign("#/signin");
              }}
            >
              <LogOut className="icon" />
            </div>
          </Tooltip>
        )}
      </header>
      <hr className="headbar-hr" />
    </>
  );
}
