import {
  HashRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";

// components
import SignInPage from "./components/signin/signin";
import DownloadPage from "./components/download_page/download_page";
import UploadPage from "./components/upload_page/upload_page";
import DSAPage from "./components/dsa_page/dsa_page";
import SetupPage from "./components/setup_page/setup_page";
import AdminPage from "./components/admin_page/admin_page";

// set up MUI colors
import { createTheme, ThemeProvider } from "@mui/material/styles";
const theme = createTheme({
  components: {
    // CTRL + SPACE to find the component you would like to override.
    // For most of them you will need to adjust just the root...
    MuiTextField: {
      styleOverrides: {
        root: {
          "& input": {
            color: "var(--pri-color)",
          },
          "& textarea": {
            color: "var(--pri-color)",
          },
          "& label": {
            color: "var(--pri-color-light)",
          },
          "& label.Mui-focused": {
            color: "var(--sec-color)",
          },
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: "var(--bor-color)",
            },
            "&:hover fieldset": {
              borderColor: "var(--sec-color)",
            },
            "&.Mui-focused fieldset": {
              borderColor: "var(--sec-color)",
            },
          },
        },
      },
    },
  },
  palette: {
    primary: {
      main: "#4287f5",
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Routes>
          <Route replace={true} path="/" element={<Navigate to="/signin" />} />
          <Route path="/dsa" element={<DSAPage />} />
          <Route path="/admin" element={<AdminPage />} />
          <Route path="/setup" element={<SetupPage />} />
          <Route path="/upload" element={<UploadPage />} />
          <Route path="/signin" element={<SignInPage />} />
          <Route path="/download" element={<DownloadPage />} />
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default App;
