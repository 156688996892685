import React from "react";
import "./upload_page.css";
import { getUploadDescription, isValidSession, uploadAsset } from "../../api";
import Dropzone from "react-dropzone";

// icons
import { Info, Trash } from "react-feather";
import Headbar from "../headbar/headbar";
import Copyright from "../copyright/copyright";
import { TextField } from "@mui/material";

export default class UploadPage extends React.Component {
  constructor(props) {
    document.title = "BinLens - Contribute to Corpus";
    super(props);
    const urlParams = new URLSearchParams(window.location.href.split("?")[1]);
    this.token = urlParams.get("token");
    this.state = {
      dragging: false,
      files: [],
      currentlyLoading: null,
      filesUploaded: "",
      description: "",
      last_description: "",
    };
    this.init = this.init.bind(this);
    this.processFiles = this.processFiles.bind(this);
    this.uploadFiles = this.uploadFiles.bind(this);
    this.hiddenFileInput = React.createRef();
  }

  componentDidMount() {
    this.init();
  }

  async init() {
    if (!(await isValidSession(this.token))) {
      window.location.assign("#/signin");
    }
    let last_description = await getUploadDescription(this.token);
    if (!last_description) last_description = "";
    this.setState({ last_description, description: "" });
  }

  processFiles(files_to_add) {
    let files = [...this.state.files];
    let new_files = [...files_to_add];

    // files each must have unique names
    for (let file of new_files) {
      files = files.filter((a) => a.name !== file.name);
    }
    files = files.concat(new_files);

    this.setState({ files, dragging: false });
  }

  async uploadFiles() {
    this.setState({
      filesUploaded: "",
    });
    for (let i = 0; i < this.state.files.length; i++) {
      let file = this.state.files[i];
      await uploadAsset(
        this.token,
        file,
        this.state.description,
        (e) => {
          this.setState({
            currentlyLoading: [file.name, e.loaded, e.total],
          });
        },
        () => {
          let files = [...this.state.files];
          files = files.filter((a) => a.name !== file.name);
          this.setState({ files }, () => {
            if (this.state.files.length === 0) {
              this.setState({
                currentlyLoading: null,
                filesUploaded: "Files uploaded!",
              });
              this.init();
            }
          });
        }
      );
    }
  }

  render() {
    return (
      <div className="upload-page">
        <Headbar
          title="ObjectSecurity BinLens Corpus Contribution Portal"
          logout
        />
        <div className="upload-center">
          <div className="upload-info">
            <h1>Information</h1>

            <TextField
              value={this.state.description}
              onChange={(e) => this.setState({ description: e.target.value })}
              multiline
              label="Description"
              fullWidth
              rows={5}
            />

            <p>
              Please enter a description before uploading OT/ICS binary files.
              Include any notable vulnerabilities and weaknesses in the file.
              Thank you.
            </p>

            <h3>Previous Description</h3>
            <p>{this.state.last_description}</p>

            <button
              onClick={this.uploadFiles}
              className={
                !this.state.currentlyLoading &&
                this.state.files.length >= 0 &&
                this.state.description !== ""
                  ? "upload-button"
                  : "upload-button-off"
              }
            >
              Upload
            </button>
          </div>
          <div className="upload-files">
            <h1>Files</h1>
            <Dropzone
              onDrop={this.processFiles}
              onDragEnter={() => this.setState({ dragging: true })}
              onDragLeave={() => this.setState({ dragging: false })}
              disableClick
            >
              {({ getRootProps, getInputProps }) => (
                <section>
                  <div
                    {...getRootProps({
                      onClick: (e) => e.stopPropagation(),
                    })}
                    className="upload-dropzone"
                    style={{
                      border: this.state.dragging
                        ? "1px dashed var(--sec-color)"
                        : "1px dashed var(--bor-color)",
                    }}
                  >
                    {this.state.currentlyLoading && (
                      <>
                        <h2>
                          Currently Uploading {this.state.currentlyLoading[0]}
                        </h2>
                        <progress
                          value={this.state.currentlyLoading[1]}
                          max={this.state.currentlyLoading[2]}
                        ></progress>
                      </>
                    )}

                    {!this.state.currentlyLoading && (
                      <>
                        <button
                          onClick={() => this.hiddenFileInput.current.click()}
                        >
                          Select Files
                        </button>
                        <input
                          type="file"
                          multiple
                          onChange={(e) => {
                            this.processFiles(e.target.files);
                          }}
                          ref={this.hiddenFileInput}
                          style={{ display: "none" }}
                        />
                        {this.state.files.length === 0 && (
                          <p>
                            Drag and drop binary files here,
                            <br />
                            or click 'select files' to browse files.
                          </p>
                        )}
                        {this.state.files.map((file, i) => {
                          return (
                            <div className="file-to-upload" key={i}>
                              <p>{file.name}</p>
                              <Trash
                                className="icon"
                                onClick={() => {
                                  let files = [...this.state.files];
                                  files = files.filter(
                                    (a) => a.name !== file.name
                                  );
                                  this.setState({ files });
                                }}
                              />
                            </div>
                          );
                        })}
                      </>
                    )}
                  </div>
                </section>
              )}
            </Dropzone>
            <a
              onClick={() =>
                alert(
                  "Contribute to the corpus by uploading binary executables, firmware, or other relevant file-types. You may add as many files as you wish before uploading. You may additionally attach a description to your upload. This message will be sent to the ObjectSecurity team. Please list any information relevant to the files you are uploading. Contributed files will be used to improve our novel binary analysis methods, and will not be shared outside of ObjectSecurity."
                )
              }
            >
              <Info className="icon" />
              What is this?
            </a>
          </div>
        </div>
        <Copyright />
      </div>
    );
  }
}
