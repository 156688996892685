import { deleteAsset } from "../../api";

export default function AdminAsset({ token, asset, refresh }) {
  return (
    <div
      className="admin-asset"
      onClick={async () => {
        if (window.confirm("Would you like to delete this asset?")) {
          await deleteAsset(token, asset._id);
          refresh();
        }
      }}
    >
      <div>
        <h3>Filename: {asset.key}</h3>
        <h3>Checksum: {asset.checksum}</h3>
      </div>
    </div>
  );
}
